import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppNavBar from "../components/AppNavBar";
import AppWrapperContainer from "../components/AppWrapperContainer";
import Image from "next/image";
import notFound from "../public/assets/images/home/notFound.svg";
import { Girl } from "@mui/icons-material";
import { reverse } from "dns";

function Custom404() {
  const [count, setCount] = useState<number>(5);

  useEffect(() => {
    goToHome();
    countdown;
  }, []);

  function goToHome() {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  }
  function goBack() {
    window.history.back();
  }
  const countdown = setInterval(() => {
    setCount(count - 1);
  }, 1000);

  return (
    <div>
      <Grid
        sx={{
          height: { xs: "100%", md: "100vh" },
          background: "linear-gradient(180deg, #E5F2FF 0%, #FFF 100%)",
        }}
      >
        <AppNavBar navBgColor="white" />
        <AppWrapperContainer>
          <Grid
            sx={{
              backgroundSize: "cover",
              display: "grid",
              placeContent: "center",
              backgroundRepeat: "no-repeat",
              padding: "2%",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                width: { xs: "100%", md: "80%" },
                display: "flex",
                gap: "12px",
                placeContent: "center",
                margin: { xs: 0, md: "8% auto" },
                flexDirection: { xs: "column-reverse", md: "row" },
              }}
            >
              <Grid sx={{ flex: "1" }}>
                <Typography
                  sx={{
                    fontSize: "40px",
                    fontWeight: 600,
                    textAlign: { xs: "center", md: "left" },
                    color: "#393F50",
                  }}
                >
                  404
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "24px", md: "32px" },
                    fontWeight: 400,
                    textAlign: { xs: "center", md: "left" },
                    color: "#393F50",
                  }}
                >
                  The page you are looking for cannot be found
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    fontWeight: 400,
                    marginTop: "4%",
                    textAlign: { xs: "center", md: "left" },
                    color: "#393F50",
                  }}
                >
                  We can’t seem to find the page you are looking for. Try going
                  back to previous page or go to the website home.
                </Typography>
                <Grid
                  sx={{
                    display: { xs: "flex", md: "none" },
                    gap: "12px",
                    marginTop: "8%",
                    paddingBottom: { xs: "5%", md: "0" },
                  }}
                >
                  <Grid sx={{ flex: { xs: "1", md: "" } }}>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ textTransform: "none" }}
                      onClick={goBack}
                    >
                      Go back
                    </Button>
                  </Grid>
                  <Grid sx={{ flex: { xs: "1", md: "" } }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ textTransform: "none" }}
                      onClick={goToHome}
                    >
                      Go to home
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: { xs: "none", md: "flex" },
                    gap: "12px",
                    marginTop: "8%",
                    paddingBottom: { xs: "5%", md: "0" },
                  }}
                >
                  <Grid>
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      onClick={goBack}
                    >
                      Go back
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      onClick={goToHome}
                    >
                      Go to home
                    </Button>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", md: "16px" },
                      fontWeight: 400,
                      marginTop: "4%",
                      textAlign: { xs: "center", md: "left" },
                      color: "#393F50",
                    }}
                  >
                    Redirecting you to the website home in{" "}
                    <span
                      style={{
                        padding: "6px 12px",
                        borderRadius: "50%",
                        border: "4px solid #00BF5D",
                        borderRight:
                          count == 4
                            ? "4px solid #fff"
                            : count == 4 ||
                              count == 3 ||
                              count == 2 ||
                              count == 1
                            ? "4px solid #fff"
                            : "",
                        borderBottom:
                          count == 3
                            ? "4px solid #fff"
                            : count == 3 || count == 2 || count == 1
                            ? "4px solid #fff"
                            : "",
                        borderLeft:
                          count == 2
                            ? "4px solid #fff"
                            : count == 1
                            ? "4px solid #fff"
                            : "",
                        borderTop: count == 1 ? "4px solid #B2E5B6" : "",
                      }}
                    >
                      {" "}
                      {count}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid sx={{ flex: "1", width: "100%" }}>
                <Grid
                  sx={{
                    display: "grid",
                    placeContent: { xs: "center", md: "flex-end" },
                  }}
                >
                  <Image
                    src={notFound}
                    alt="notFoundimg"
                    style={{
                      height: "300px",
                      width: "300px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
        </AppWrapperContainer>
      </Grid>
    </div>
  );
}

export default Custom404;
