import { Grid } from "@mui/material";
import AppNavBar from "./AppNavBar";

export default function AppWrapperContainer({
  children,
  nav,
  navBgColor,
}: {
  children: any;
  nav?: boolean;
  navBgColor?: string;
}) {
  return (
    <Grid sx={{ paddingTop: { xs: "55px", md: "72px" } }}>
      {nav ? <AppNavBar navBgColor={navBgColor} /> : null}
      {children}
    </Grid>
  );
}
